import React from "react";

function Upi() {
  return (
    <svg
      width="71"
      height="24"
      viewBox="0 0 71 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.9062 20L64.5271 12.1357L60.4436 4L55.9062 20Z"
        fill="#097939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53 20L61.6209 12.1357L57.5374 4L53 20Z"
        fill="#ED752E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2044 5.13558L9.18944 15.8731L19.8929 15.9481L22.8329 5.13558H25.5464L22.0214 17.7056C21.8864 18.1856 21.3724 18.5756 20.8739 18.5756H7.15444C6.32244 18.5756 5.82944 17.9256 6.05444 17.1256L9.41644 5.13558H12.2044ZM45.2954 5.06058H48.0089L44.2399 18.5756H41.4499L45.2954 5.06058ZM25.8484 10.6916L39.4164 10.6166L40.3214 7.76308H26.5264L27.3554 5.13508L42.0554 5.00008C42.9704 4.99158 43.5154 5.70008 43.2744 6.58358L41.8894 11.6456C41.6479 12.5286 40.7094 13.2441 39.7944 13.2441H27.6574L26.2264 18.8006H23.5864L25.8484 10.6916Z"
        fill="#747474"
      />
    </svg>
  );
}

export default Upi;
